import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import { IRepresentative } from '#/types/representative';
import {
  invalidateRepresentativeQueries,
  useAddRepresentative,
  useUpdateRepresentative,
} from '#/api/representativeQueries';
import { useGetContractParties } from '#/api/marriageContract';
import { invalidateRegisterOfAssets } from '#/api/assetsRegister';

const countries = [{ code: 'CH', label: 'global.switzerland' }];

type Props = {
  sx?: any;
  representative?: IRepresentative;
  isEdit?: boolean;
  handleEditMode?: () => void;
  handleAddMore?: () => void;
  handleLastAdded?: React.Dispatch<React.SetStateAction<string | null>>;
  marriageContractValidation?: boolean;
};

const RepresentativesForm = ({
  sx,
  representative,
  isEdit = false,
  handleEditMode,
  handleAddMore,
  handleLastAdded,
  marriageContractValidation,
}: Props) => {
  const { translate } = useLocales();

  const { mutateAsync: updateRepresentative, isLoading: updateLoading } =
    useUpdateRepresentative(representative?.id);

  const { mutateAsync: addRepresentative, isLoading: addLoading } =
    useAddRepresentative();

  const { data: contractParties } = useGetContractParties();

  const RepresentativeSchema = Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),
    date_of_birth: Yup.date()
      .required('validations.user.date_of_birth.required')
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        'validations.user.date_of_birth.max'
      ),
    address: Yup.object().shape({
      street: Yup.string().required('validations.adress.street'),
      house_number: Yup.string().required('validations.adress.house_number'),
      zip_code: Yup.string().required('validations.adress.zip_code'),
      city: Yup.string().required('validations.adress.city'),
      country: Yup.string().required('validations.adress.country'),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      first_name: representative?.first_name || '',
      last_name: representative?.last_name || '',
      date_of_birth: representative?.date_of_birth || null,
      address: {
        street: representative?.address.street || '',
        house_number: representative?.address.house_number || '',
        zip_code: representative?.address.zip_code || '',
        city: representative?.address.city || '',
        country: representative?.address.country || 'CH',
      },
    }),
    [representative]
  );

  const methods = useForm<IRepresentative>({
    defaultValues,
    // @ts-expect-error
    resolver: yupResolver(RepresentativeSchema),
  });

  const {
    setValue,
    reset,
    handleSubmit,
    watch,
    setError,
    formState: { isDirty, errors },
  } = methods;

  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const fieldNames = {
    'address.street': 'route',
    'address.house_number': 'street_number',
    'address.zip_code': 'postal_code',
    'address.city': 'locality',
  };

  const isStreetError = Boolean(errors?.address?.street);

  const onSubmit = async (data: any) => {
    const reqData = {
      ...data,
      date_of_birth: data.date_of_birth
        ? new Date(data.date_of_birth).toISOString().split('T')[0]
        : null,
    };

    const sameAsContractParty = contractParties?.results.find(
      (party) =>
        party.first_name === reqData.first_name &&
        party.last_name === reqData.last_name
    );

    if (sameAsContractParty && marriageContractValidation) {
      setError('first_name', {
        type: 'manual',
        message: String(
          translate('validations.marriage.sameAsContractParties')
        ),
      });
      setError('last_name', {
        type: 'manual',
        message: String(
          translate('validations.marriage.sameAsContractParties')
        ),
      });
      return;
    }

    if (isEdit) {
      await updateRepresentative(reqData)
        .then(() => {
          toast.success(
            String(
              translate('toast_notifications.success.representative_update')
            )
          );
          if (handleEditMode) handleEditMode();
          invalidateRepresentativeQueries.getRepresentatives();
          invalidateRegisterOfAssets.commonBeneficiariesList();
        })
        .catch(() => {
          toast.error(
            String(translate('toast_notifications.error.representative_update'))
          );
        });
    } else {
      await addRepresentative(reqData)
        .then((res) => {
          toast.success(
            String(translate('toast_notifications.success.representative_add'))
          );
          if (handleAddMore) handleAddMore();
          if (handleLastAdded) handleLastAdded(res.data.id);
          invalidateRepresentativeQueries.getRepresentatives();
          invalidateRegisterOfAssets.commonBeneficiariesList();
        })
        .catch(() => {
          toast.error(
            String(translate('toast_notifications.error.representative_add'))
          );
        });
    }
  };

  const isSubmitDisabled =
    (isEdit && JSON.stringify(defaultValues) === JSON.stringify(watch())) ||
    (!isEdit && !isDirty);

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem('addedFor');
  //   };
  // }, []);

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={2}
          sx={{
            mb: 1,
          }}
        >
          <Stack
            direction={{
              sm: 'column',
              md: 'row',
            }}
            spacing={{
              xs: 1,
              sm: 2,
            }}
          >
            <RHFTextField
              name="first_name"
              label={String(translate('global.formLabels.firstName'))}
            />
            <RHFTextField
              name="last_name"
              label={String(translate('global.formLabels.lastName'))}
            />
          </Stack>
          <Stack
            direction={{
              sm: 'column',
              md: 'row',
            }}
            spacing={{
              xs: 1,
              sm: 2,
            }}
          >
            <Stack
              sx={{
                width: '100%',
              }}
            >
              <AutoAddressPicker
                fieldNames={fieldNames}
                streetError={isStreetError}
                name="address.street"
              />
            </Stack>
            <RHFTextField
              name="address.house_number"
              label={String(translate('global.formLabels.houseNumber'))}
              onChange={(e) => {
                setValue('address.house_number', e.target.value, {
                  shouldValidate: true,
                });
              }}
            />
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              sm: 'column',
              md: 'row',
            }}
            spacing={2}
          >
            <RHFTextField
              name="address.zip_code"
              label={String(translate('global.formLabels.postalCode'))}
              onChange={(e) => {
                setValue('address.zip_code', e.target.value, {
                  shouldValidate: true,
                });
              }}
            />
            <RHFTextField
              name="address.city"
              label={String(translate('global.formLabels.city'))}
              onChange={(e) => {
                setValue('address.city', e.target.value, {
                  shouldValidate: true,
                });
              }}
            />
          </Stack>
          <RHFDatePicker
            name="date_of_birth"
            label={String(translate('global.formLabels.date_of_birth'))}
            disableFuture
          />
          <RHFSelect
            name="address.country"
            label={String(translate('global.formLabels.country'))}
            value="CH"
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {String(translate(country.label))}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
        <LoadingButton
          variant="contained"
          size="small"
          color="secondary"
          sx={{
            width: '120px',
          }}
          startIcon={<Iconify icon="mdi:content-save" />}
          onClick={() => handleSubmit(onSubmit)()}
          loading={updateLoading || addLoading}
          disabled={isSubmitDisabled}
        >
          <Typography variant="caption">
            {String(translate('global.save'))}
          </Typography>
        </LoadingButton>
        {(handleAddMore || isEdit) && (
          <Button
            size="small"
            variant="outlined"
            onClick={handleAddMore || handleEditMode}
            sx={{ marginLeft: 2 }}
            startIcon={<Iconify icon="mdi:close" />}
          >
            <Typography variant="caption">
              {String(translate('global.cancel'))}
            </Typography>
          </Button>
        )}
      </FormProvider>
    </Box>
  );
};

export default RepresentativesForm;
